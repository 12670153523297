/* line 5, node_modules/bootstrap/scss/_tables.scss */
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: var(--color-black);
  background-color: #fff;
}

/* line 11, node_modules/bootstrap/scss/_tables.scss */
.table th,
.table td {
  padding: 0.75em;
  vertical-align: top;
  border-top: 1px solid var(--color-lightgrey);
}

/* line 18, node_modules/bootstrap/scss/_tables.scss */
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid var(--color-lightgrey);
}

/* line 23, node_modules/bootstrap/scss/_tables.scss */
.table tbody + tbody {
  border-top: 2px solid var(--color-lightgrey);
}

/* line 34, node_modules/bootstrap/scss/_tables.scss */
.table-sm th,
.table-sm td {
  padding: 0.33em;
}

/* line 45, node_modules/bootstrap/scss/_tables.scss */
.table-bordered {
  border: 1px solid var(--color-lightgrey);
}

/* line 48, node_modules/bootstrap/scss/_tables.scss */
.table-bordered th,
.table-bordered td {
  border: 1px solid var(--color-lightgrey);
}

/* line 54, node_modules/bootstrap/scss/_tables.scss */
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

/* line 62, node_modules/bootstrap/scss/_tables.scss */
.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

/* line 75, node_modules/bootstrap/scss/_tables.scss */
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f4f4f4;
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover tbody tr:hover {
  color: var(--color-black);
  background-color: rgba(0, 0, 0, 0.075);
}

/* line 7, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

/* line 32, node_modules/bootstrap/scss/mixins/_table-row.scss */
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

/* line 114, node_modules/bootstrap/scss/_tables.scss */
.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

/* line 122, node_modules/bootstrap/scss/_tables.scss */
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: var(--color-lightgrey);
}

/* line 130, node_modules/bootstrap/scss/_tables.scss */
.table-dark {
  color: #fff;
  background-color: #343a40;
}

/* line 134, node_modules/bootstrap/scss/_tables.scss */
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

/* line 140, node_modules/bootstrap/scss/_tables.scss */
.table-dark.table-bordered {
  border: 0;
}

/* line 145, node_modules/bootstrap/scss/_tables.scss */
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

/* line 13, node_modules/bootstrap/scss/mixins/_hover.scss */
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  /* line 171, node_modules/bootstrap/scss/_tables.scss */
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  /* line 179, node_modules/bootstrap/scss/_tables.scss */
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  /* line 171, node_modules/bootstrap/scss/_tables.scss */
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  /* line 179, node_modules/bootstrap/scss/_tables.scss */
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  /* line 171, node_modules/bootstrap/scss/_tables.scss */
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  /* line 179, node_modules/bootstrap/scss/_tables.scss */
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  /* line 171, node_modules/bootstrap/scss/_tables.scss */
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  /* line 179, node_modules/bootstrap/scss/_tables.scss */
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

/* line 171, node_modules/bootstrap/scss/_tables.scss */
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/* line 179, node_modules/bootstrap/scss/_tables.scss */
.table-responsive > .table-bordered {
  border: 0;
}

/* line 1, node_modules/bootstrap/scss/_transitions.scss */
.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  /* line 1, node_modules/bootstrap/scss/_transitions.scss */
  .fade {
    transition: none;
  }
}

/* line 4, node_modules/bootstrap/scss/_transitions.scss */
.fade:not(.show) {
  opacity: 0;
}

/* line 10, node_modules/bootstrap/scss/_transitions.scss */
.collapse:not(.show) {
  display: none;
}

/* line 15, node_modules/bootstrap/scss/_transitions.scss */
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  /* line 15, node_modules/bootstrap/scss/_transitions.scss */
  .collapsing {
    transition: none;
  }
}

/* HEADER */
/* MAIN */
/* FOOTER */
/* PRODUCT MINIATURE */
/* product title */
/** LAYOUT **/
/** PAGES **/
/** COMPONENT **/

/*# sourceMappingURL=classic-rocket.css.map */